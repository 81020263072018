#about, #about p {
    text-align: center;
}
.post-list {
  padding: 0;

  .post-item {
    margin-bottom: 1rem;
    margin-left: 0;
    list-style-type: none;

    .meta {
      display: block;
      margin-right: 16px;
      min-width: 100px;
      color: $color-meta;
      font-size: 14px;
    }
    a {
        background-image: none;
        &:hover {
            background-image: linear-gradient(transparent, transparent 50%, $color-link 50%, $color-link);
        }
}
  }
}
@media (min-width: 480px) {
  .post-list {
    .post-item {
      display: flex;
      margin-bottom: 5px;

      .meta {
        text-align: left;
      }
    }
  }
}
.project-list {
  padding: 0;
  list-style: none;

  .project-item {
    margin-bottom: 5px;
    p {
      display: inline;
    }
  }
}
