$color-cets: #AA3939;
$color-cets-1: #FFAAAA;
$color-primary-2: #D46A6A;
$color-primary-3: #801515;
$color-primary-4: #550000;

$color-software: #AA6C39;
$color-software-1: #FFD1AA;
$color-software-2: #D49A6A;
$color-software-3: #804515;
$color-software-4: #552700;

$color-systems: #226666;
$color-systems-1: #669999;
$color-systems-2: #407F7F;
$color-systems-3: #0D4D4D;
$color-systems-4: #003333;

$color-selfsuff: #2D882D;
$color-selfsuff-1: #88CC88;
$color-selfsuff-2: #55AA55;
$color-selfsuff-3: #116611;
$color-selfsuff-4: #004400;

$color-meta: #d8d8d8;
