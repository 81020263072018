$color-background: #FFFFFF;
$color-footer-mobile-1: darken($color-background, 2%);
$color-footer-mobile-2: darken($color-background, 10%);
$color-background-code: darken($color-background, 2%);
$color-border: #666;
$color-meta: #666;
$color-meta-code: lighten($color-meta, 10%);
$color-link: lighten( #226666, 10% );
$color-text: #383838;
$color-accent-3: #8c8c8c;
$color-accent-2: #383838;
$color-accent-1: #2bbc8a;
$color-quote: #2bbc8a;
